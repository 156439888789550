/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import branchActionTypes from './branch.types';
import generalTypesAction from '../genera.types';
import {
	createBranch,
	getAllBranch,
	updateBranch,
	deleteBranch,
	postCodeCreate,
	createBranchPinCode,
	deleteBranchPinCode,
	getAllBranchPinCode,
} from './branch.services';
import {
	getAllListSuccess,
	getAllListFailure,
	getAllListChanged,
	createBranchSuccess,
	createBranchFailure,
	branchCreateChanged,
	updateBranchSuccess,
	updateBranchFailure,
	branchUpdateChanged,
	deleteBranchSuccess,
	deleteBranchFailure,
	branchDeleteChanged,
	postCodeCreateSuccess,
	postCodeCreateFailure,
	postCodeResponseChanged,
	branchCreatePinCodeChanged,
	createBranchPinCodeSuccess,
	createBranchPinCodeFailure,
	branchDeletePinCodeChanged,
	deleteBranchPinCodeSuccess,
	deleteBranchPinCodeFailure,
	getAllBranchPinCodeSuccess,
	getAllBranchPinCodeFailure,
	getAllBranchPinCodeChanged,
} from './branch.actions';
import { encodeJson } from '../enode-decode';

export function* createNewBranch({ payload: { values } }) {
	try {
		const jsonData = {
			BranchUID: values.branchUID,
			BranchName: values.branchName,
			BranchAddress: values.branchAddress,
			BranchCity: values.branchCity,
			BranchState: values.branchState,
			BranchDistrict: values.branchDistrict,
			BranchCountry: values.branchCountry,
			BranchPhoto: values.branchPhoto,
			CompanyID: values.companyID,
			CreatedBy: values.userID,
			BranchZipCode: values.branchPincode,
			BranchPostOffice: values.selectedPinCode,
		};
		const BranchData = encodeJson(jsonData, generalTypesAction.BRANCH);
		const responseData = yield call(createBranch, BranchData);
		if (responseData.data.statusCode === '100') {
			yield put(createBranchFailure(responseData.data));
		} else {
			yield put(createBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createBranchFailure(error));
	}
}

export function* updateBranchDetails({ payload: { values } }) {
	try {
		const jsonData = {
			BranchID: values.branchID,
			BranchUID: values.branchUID,
			BranchName: values.branchName,
			BranchAddress: values.branchAddress,
			BranchCity: values.branchCity,
			BranchState: values.branchState,
			BranchDistrict: values.branchDistrict,
			BranchCountry: values.branchCountry,
			CompanyID: values.companyID,
			UserID: values.userID,
			BranchZipCode: values.branchPincode,
			BranchPostOffice: values.selectedPinCode,
		};
		const BranchData = encodeJson(jsonData, generalTypesAction.BRANCH);
		const responseData = yield call(updateBranch, BranchData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBranchFailure(responseData.data));
		} else {
			yield put(updateBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBranchFailure(error));
	}
}

export function* deleteBranchDetails({ payload: { branchID, userID } }) {
	try {
		const jsonData = {
			BranchID: branchID,
			UserID: userID,
		};
		const BranchData = encodeJson(jsonData, generalTypesAction.BRANCH);
		const responseData = yield call(deleteBranch, BranchData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBranchFailure(responseData.data));
		} else {
			yield put(deleteBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBranchFailure(error));
	}
}

export function* createListOfBranch({ payload: { limit, companyID } }) {
	try {
		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const BranchData = encodeJson(jsonData, generalTypesAction.BRANCH);
		const responseData = yield call(getAllBranch, BranchData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* postCodeCreateWithValues({ payload: { value } }) {
	try {
		const responseData = yield call(postCodeCreate, value);
		if (responseData.data[0].Status !== 'Success') {
			yield put(postCodeCreateFailure(responseData.data[0]));
		} else {
			yield put(postCodeCreateSuccess(responseData.data[0]));
		}
	} catch (error) {
		yield put(postCodeCreateFailure(error));
	}
}

export function* createNewBranchPinCode({ payload: { pincodeValues, branchID, userID } }) {
	try {
		const jsonData = {
			BranchID: branchID,
			PinCodeArr: pincodeValues,
			CreatedBy: userID,
		};
		const BranchPinCodeData = encodeJson(jsonData, generalTypesAction.BRANCHPINCODE);
		const responseData = yield call(createBranchPinCode, BranchPinCodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createBranchPinCodeFailure(responseData.data));
		} else {
			yield put(createBranchPinCodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createBranchPinCodeFailure(error));
	}
}

export function* deleteNewBranchPinCode({ payload: { PinCodeValue, branchID } }) {
	try {
		const jsonData = {
			BranchID: branchID,
			PinCode: PinCodeValue,
		};
		const BranchPinCodeDeleteData = encodeJson(jsonData, generalTypesAction.BRANCHPINCODE);
		const responseData = yield call(deleteBranchPinCode, BranchPinCodeDeleteData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBranchPinCodeFailure(responseData.data));
		} else {
			yield put(deleteBranchPinCodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBranchPinCodeFailure(error));
	}
}

export function* allBranchPinCodeStart({ payload: { id } }) {
	try {
		const jsonData = {
			BranchID: id,
		};
		const BranchPinCodeListData = encodeJson(jsonData, generalTypesAction.BRANCHPINCODE);
		console.log('BranchPinCodeListData', BranchPinCodeListData);
		const responseData = yield call(getAllBranchPinCode, BranchPinCodeListData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllBranchPinCodeFailure(responseData.data));
		} else {
			yield put(getAllBranchPinCodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllBranchPinCodeFailure(error));
	}
}

export function* createFilterListOfBranch({ payload: { searchVal, companyID, } }) {
	try {
		const jsonData = {
			SearchVal: searchVal,
			CompanyID: companyID,
		};
		const BranchData = encodeJson(jsonData, generalTypesAction.BRANCH);
		const responseData = yield call(getAllBranch, BranchData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createListOfBranchReset() {
	yield put(getAllListChanged());
}

export function* postCodeReset() {
	yield put(postCodeResponseChanged());
}

export function* createBranchResponseReset() {
	yield put(branchCreateChanged());
}

export function* updateBranchResponseReset() {
	yield put(branchUpdateChanged());
}

export function* deleteBranchResponseReset() {
	yield put(branchDeleteChanged());
}

export function* createBranchPinCodeResponseReset() {
	yield put(branchCreatePinCodeChanged());
}

export function* deleteBranchPinCodeResponseReset() {
	yield put(branchDeletePinCodeChanged());
}

export function* allBranchPinCodeReset() {
	yield put(getAllBranchPinCodeChanged());
}

export function* branchCreateStart() {
	yield takeLatest(branchActionTypes.BRANCH_CREATE_REPSONSE_START, createNewBranch);
}

export function* branchCreateResponseReset() {
	yield takeLatest(branchActionTypes.BRANCH_CREATE_REPSONSE_RESET, createBranchResponseReset);
}

export function* branchUpdateStart() {
	yield takeLatest(branchActionTypes.BRANCH_UPDATE_REPSONSE_START, updateBranchDetails);
}

export function* branchUpdateResponseReset() {
	yield takeLatest(branchActionTypes.BRANCH_UPDATE_REPSONSE_RESET, updateBranchResponseReset);
}

export function* branchDeleteStart() {
	yield takeLatest(branchActionTypes.BRANCH_DELETE_REPSONSE_START, deleteBranchDetails);
}

export function* branchDeleteResponseReset() {
	yield takeLatest(branchActionTypes.BRANCH_DELETE_REPSONSE_RESET, deleteBranchResponseReset);
}

export function* getAllBranchList() {
	yield takeLatest(branchActionTypes.BRANCH_LIST_START, createListOfBranch);
}

export function* getAllBranchListReset() {
	yield takeLatest(branchActionTypes.BRANCH_LIST_RESET, createListOfBranchReset);
}

export function* onPostcodeCreation() {
	yield takeLatest(branchActionTypes.POSTCODE_CREATE_RESPONSE_START, postCodeCreateWithValues);
}

export function* onPostcodeChange() {
	yield takeLatest(branchActionTypes.POSTCODE_CREATE_RESPONSE_RESET, postCodeReset);
}

export function* branchPinCodeStart() {
	yield takeLatest(branchActionTypes.BRANCH_PINCODE_RESPONSE_START, createNewBranchPinCode);
}

export function* branchPinCodeResponseReset() {
	yield takeLatest(
		branchActionTypes.BRANCH_PINCODE_RESPONSE_RESET,
		createBranchPinCodeResponseReset,
	);
}

export function* branchPinCodeDeleteStart() {
	yield takeLatest(branchActionTypes.BRANCH_PINCODE_DELETE_START, deleteNewBranchPinCode);
}

export function* branchPinCodeDeleteResponseReset() {
	yield takeLatest(
		branchActionTypes.BRANCH_PINCODE_DELETE_RESET,
		deleteBranchPinCodeResponseReset,
	);
}

export function* getAllBranchPinCodeListStart() {
	yield takeLatest(branchActionTypes.BRANCH_PINCODE_LIST_START, allBranchPinCodeStart);
}

export function* branchPinCodeListResponseReset() {
	yield takeLatest(branchActionTypes.BRANCH_PINCODE_LIST_RESET, allBranchPinCodeReset);
}

export function* getAllBranchFilterList() {
	yield takeLatest(branchActionTypes.BRANCH_FILTER_SEARCH_LIST_START, createFilterListOfBranch);
}

export function* branchSaga() {
	yield all([
		call(getAllBranchList),
		call(getAllBranchListReset),
		call(branchCreateStart),
		call(branchCreateResponseReset),
		call(branchUpdateStart),
		call(branchUpdateResponseReset),
		call(branchDeleteStart),
		call(branchDeleteResponseReset),
		call(onPostcodeCreation),
		call(onPostcodeChange),
		call(branchPinCodeStart),
		call(branchPinCodeResponseReset),
		call(branchPinCodeDeleteStart),
		call(branchPinCodeDeleteResponseReset),
		call(getAllBranchPinCodeListStart),
		call(branchPinCodeListResponseReset),
		call(getAllBranchFilterList),
	]);
}
