/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getMemberBankAnalyse = () => {
	const url = `${PAISANOW_API_URL}Incredo/supportedBanks.php`;
	return axios.get(url, { headers: { 'Content-type': 'application/json' } });
};

export const getMemberUpdateBankAnalyse = (bankData) => {
	const data = {
		BankStatementAnalysisToken: bankData,
	};
	const url = `${PAISANOW_API_URL}UploadMemberBankStatementAnalysis`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getMemberBankInkredo = (filePkData) => {
	const url = `${PAISANOW_API_URL}Incredo/bankStatements.php/?file_pk=${filePkData}`;
	return axios.get(url, { headers: { 'Content-type': 'application/json' } });
};

export const getMemberUpdateBankInkredoJson = (bankInkredoJsonData) => {
	const data = {
		BankStatementInkredoAnalysisJsonToken: bankInkredoJsonData,
	};
	const url = `${PAISANOW_API_URL}UpdateBankInkredoJson`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createBankAccount = (BankAccountData) => {
	debugger;
	const data = {
		BanckAccountCreateToken: BankAccountData,
	};
	const url = `${PAISANOW_API_URL}AddBankAccountDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BankIFSCCreate = (BankIFSCData) => {
	const url = `https://ifsc.razorpay.com/${BankIFSCData}`;
	return axios.get(url);
};

