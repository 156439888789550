/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import bankActionTypes from './bank.types';

export const memberBorrowerBankAnalyseStart = () => ({
	type: bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_START,
});

export const memberBorrowerBankAnalyseSuccess = (details) => ({
	type: bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_SUCCESS,
	payload: details,
});

export const memberBorrowerBankAnalyseFailure = (error) => ({
	type: bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_FAILURE,
	payload: error,
});

export const memberBorrowerBankAnalyseChanged = () => ({
	type: bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_CHANGED,
});

export const memberBorrowerBankAnalyseReset = () => ({
	type: bankActionTypes.MEMBER_ANALYSE_BANK_STATEMENT_RESET,
});

export const memberUpdateBankAnalyseStart = (bankDetails) => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_START,
	payload: bankDetails,
});

export const memberUpdateBankAnalyseSuccess = (details) => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_SUCCESS,
	payload: details,
});

export const memberUpdateBankAnalyseFailure = (error) => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_FAILURE,
	payload: error,
});

export const memberUpdateBankAnalyseChanged = () => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_CHANGED,
});

export const memberUpdateBankAnalyseReset = () => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_RESET,
});

export const memberBorrowerBankInkredoStart = (bankFilePK) => ({
	type: bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_START,
	payload: bankFilePK,
});

export const memberBorrowerBankInkredoSuccess = (details) => ({
	type: bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_SUCCESS,
	payload: details,
});

export const memberBorrowerBankInkredoFailure = (error) => ({
	type: bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_FAILURE,
	payload: error,
});

export const memberBorrowerBankInkredoChanged = () => ({
	type: bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_CHANGED,
});

export const memberBorrowerBankInkredoReset = () => ({
	type: bankActionTypes.MEMBER_INKREDO_BANK_STATEMENT_RESET,
});

export const memberUpdateBankInkredoJsonStart = (bankInkredo) => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_START,
	payload: bankInkredo,
});

export const memberUpdateBankInkredoJsonSuccess = (details) => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_SUCCESS,
	payload: details,
});

export const memberUpdateBankInkredoJsonFailure = (error) => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_FAILURE,
	payload: error,
});

export const memberUpdateBankInkredoJsonChanged = () => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_CHANGED,
});

export const memberUpdateBankInkredoJsonReset = () => ({
	type: bankActionTypes.UPDATE_MEMBER_ANALYSE_BANK_STATEMENT_JSON_RESET,
});

// Bank Account Add

export const createBankAccountSuccess = (BankAccount) => ({
	type: bankActionTypes.BANK_ACCOUNT_CREATE_REPSONSE_SUCCESS,
	payload: BankAccount,
});

export const createBankAccountFailure = (error) => ({
	type: bankActionTypes.BANK_ACCOUNT_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createBankAccountStart = (BankAccountDetails) => ({
	type: bankActionTypes.BANK_ACCOUNT_CREATE_REPSONSE_START,
	payload: BankAccountDetails,
});

export const bankAccountCreateChanged = () => ({
	type: bankActionTypes.BANK_ACCOUNT_CREATE_REPSONSE_CHANGED,
});

export const bankAccountCreateReset = () => ({
	type: bankActionTypes.BANK_ACCOUNT_CREATE_REPSONSE_RESET,
});

// End Bank Account Add

// Start Bank IFSC Search
export const bankIFSCcodeStart = (IFSCcredentails) => ({
	type: bankActionTypes.BANK_IFSC_CODE_RESPONSE_START,
	payload: IFSCcredentails,
});

export const bankIFSCcodeSuccess = (IFSC) => ({
	type: bankActionTypes.BANK_IFSC_CODE_RESPONSE_SUCCESS,
	payload: IFSC,
});

export const bankIFSCcodeFailure = (IFSC) => ({
	type: bankActionTypes.BANK_IFSC_CODE_RESPONSE_FAILURE,
	payload: IFSC,
});

export const bankIFSCcodeChanged = () => ({
	type: bankActionTypes.BANK_IFSC_CODE_RESPONSE_CHANGED,
});

export const bankIFSCcodeReset = () => ({
	type: bankActionTypes.BANK_IFSC_CODE_RESPONSE_RESET,
});
// End Bank IFSC Search